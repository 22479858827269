import React from 'react';
import {
    Col, Row
  } from 'reactstrap';


const ContactInfo = () => {
    return (
        <Row className="contact-info-wrapper">
            <Col xs="12" sm="4">
                <div className="contact-info-content align-left">
                <h3>Phone</h3>
                <span>
                <a className="contact-info" href="tel:1-855-816-7705">1-855-816-7705</a><br/>
                Mon - Fri 8:30AM - 5:30PM PST<br/>
                (U.S. Support)
                </span>
                </div>
            </Col>

            <Col xs="12" sm="4">
                <div className="contact-info-content align-left">
                <h3>Europe</h3>
                <span>
                <a className="contact-info" href="mailto:support@ihealthlabs.eu">support@ihealthlabs.eu</a><br/>
                www.ihealthlabs.eu<br/>
                (European Support)
                </span>
                </div>
            </Col>
        </Row>
    )
}

export default ContactInfo;