import React from 'react';
import {
  Container, Col, Form,
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import checkImg from './imgs/check.svg';
import {lang} from './constants/enterprisePurchase/hk';

export default class CovidTestKitFormHK extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contact_title: '',
            organization_name: '',
            organization_type: '',
            address1: '',
            address2:'',
            product_name: '',
            quantity: '101',
            receive_date: '',
            note: '',
            receive_updates: false,
            submitted: false,
            validate: {},
            error: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount(){
        this.props.sendPostMessage({type:'resize', target: window.document });
    }

    resetForm() {
        this.setState({first_name: '',
        last_name: '',
        email: '',
        phone: '',
        contact_title: '',
        organization_name: '',
        organization_type: '',
        state: '',
        address2: '',
        zip: '',
        address1: '',
        address2: '',
        product_name: '',
        quantity: '101',
        receive_date: '',
        note: '',
        receive_updates: false,
        submitted: false,
        validate: {},
        error: ''});
    }


    // check all fields in state are filled
    async isFormValid(field) {
        if(field) {
            return this.state.validate[field]===true;
        }
       const fields = ['first_name', 'last_name', 'email', 'phone', 'contact_title', 'organization_name', 'organization_type', 'address1', 'product_name','quantity', 'receive_date'].reduce((o, key) => Object.assign(o, {[key]: this.state[key]!==''}), {});
        if(this.state.quantity<101) {
            fields.quantity = false;
        }
        const valid = Object.keys(this.state.validate).length !== 0 && Object.values(fields).every(value => value === true);
        if(!valid) {
            await this.setState({validate: fields});
            return undefined;
        }
        else {
            return true;
        }
    }



    handleChange = async (event) => {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if(value==="true"){
          value = true;
        }else if (value==="false"){
          value = false;
        }
        const { name } = target;
        const valid = value !== '';
        const validate = Object.assign({}, this.state.validate, {[name]: valid});
        await this.setState({
          [name]: value,
            validate
        });
    }


    handleSubmit = async (event) => {
        const {props} = this;
        event.preventDefault();
        const r = await this.isFormValid();
        if(r === undefined) {
            return;
        }
        const allTrue = Object.keys(this.state.validate).length !== 0 && Object.values(this.state.validate).every(value => value === true);
        if(allTrue){
            const data = {...this.state};
            delete data.validate;
            delete data.error;
            delete data.submitted;
            fetch('/forms/hk-covid-test-kit',
            {
              method: "POST",
              cache: "no-cache",
              mode: 'cors',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.state)
            }).then(response => {
              return response.json();
              // return response.json(); //then consume it again, the error happens
            }).then(r => {
              console.log(r);
              if(r.message.indexOf('error') >= 0) {
                  console.log(r.message);
                this.setState({error: r.message});
              }
              else if(r.message === 'success'){
                this.setState({submitted: true});
              }
              else {
                alert(props.errorMessage);
              }
            })
            .catch((e)=>{
              console.log(e);
              this.setState({error: e});
            })
        }
        else{
            this.isFormValid();
        }
    }

    // render form section
    renderFormSection = () => {
        const {props} = this;
        return ( <div className="form-content">
            <Form>
                <FormGroup row>
                    
                    <Col md="6">
                        <Label for="last_name">
                        {props.lastName}
                        </Label>
                        <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.last_name===false}
                        />
                        <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="first_name">
                        {props.firstName}
                        </Label>
                        <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                        invalid={this.state.validate.first_name===false}
                        />
                        <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="6">
                        <Label for="email">
                        {props.email}
                        </Label>
                        <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.email===false}
                        /> 
                        <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="phone">
                        {props.phone}
                        </Label>
                        <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.phone===false}
                        />
                        <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="contact_title">
                    {props.title}
                    </Label>
                    <Input
                    id="contact_title"
                    name="contact_title"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.contact_title===false}
                    />
                    <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                
                <FormGroup row>
                    <Col md="12">
                    <Label for="organization_name">
                    {props.organization}
                    </Label>
                    <Input
                    id="organization_name"
                    name="organization_name"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.organization_name===false}
                    />
                    <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="organization_type">
                    {props.organizationType}
                    </Label>
                    <Input
                    id="organization_type"
                    name="organization_type"
                    type="select"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.organization_type===false}
                    >
                        <option></option>
                       {props.orgTypeList.map((item, index) => {
                            return <option key={index} value={item}>{item}</option>
                        })
                       }
                    </Input>
                    <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="address1">
                    {props.address1}
                    </Label>
                    <Input
                    id="address1"
                    name="address1"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.address1===false}
                    />
                    <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="address2">
                    {props.address2}
                    </Label>
                    <Input
                    id="address2"
                    name="address2"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="product_name">
                    {props.productName}
                    </Label>
                    <Input
                    id="product_name"
                    name="product_name"
                    type="select"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.product_name===false}
                    >
                        <option></option>
                        <option>
                            2 Tests Per Pack
                        </option>
                        <option>
                            40 Tests Per Pack
                        </option>
                    </Input>
                    <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="6">
                    <Label for="quantity">
                    {props.quantity}
                    </Label>
                    <Input
                    id="quantity"
                    name="quantity"
                    type="number"
                    min='101'
                    value={this.state.quantity}
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.quantity===false}
                    />
                    <FormFeedback>
                        {props.minimum}
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="receive_date">
                            {props.date}
                        </Label>
                        <Input
                        id="receive_date"
                        name="receive_date"
                        type="date"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.receive_date===false}
                        />
                        <FormFeedback>
                        {props.required}
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="note">
                    {props.note}
                    </Label>
                    <Input
                    id="note"
                    name="note"
                    type="textarea"
                    placeholder={props.notePlaceHolder}
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12" style={{paddingLeft:"35px"}}>
                    <Label for="receive_updates" className="justify-content-center" style={{fontWeight: 'normal'}}>
                    <Input
                    id="receive_updates"
                    name="receive_updates"
                    type="checkbox"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                    {props.subscribe}
                    </Label>
                    </Col>
                </FormGroup>
                <button onClick={(e)=>{
                    this.handleSubmit(e)
                }} className="btn" style={{color: 'white', fontWeight: 'bold', fontSize:'24px', width: '100%'}}>
                    {props.submit}
                </button>
                </Form>
        </div>);
    }

    render() {
        const {props} = this;

        if(this.state.submitted) {
            return <div className="d-flex flex-column align-items-center mt-3 mb-3" style={{width: "100%"}}>
                    <img src={checkImg} alt="Success" style={{width: '87.5px', height: '87.5px', marginBottom: '41px'}}/>
                    <p style={{marginBottom: "64px"}}> {props.thankyou}</p>
                    <button className="btn" style={{color: '#fff', padding: "8px 42px"}} onClick={()=>this.resetForm()}>
                        Ok
                    </button>
                </div>;
        } else if ( this.state.error) {
            return(<div className="d-flex flex-column align-items-center mt-3 mb-3" style={{width: "100%"}}>
                <h1>{props.error}</h1>
                <p>{props.errorMessage}</p>
                </div>)
        }
        return (
            <Container className="d-flex flex-column align-items-center mt-3 mb-3" lang={props.lang}>
                <div id="CovidTestKitForm" className="" style={{maxWidth: '706px'}}>
                    {this.renderFormSection()}
                </div>
            </Container>
        );
    }


}