import React from 'react';


export default (props) => {
    return (
        <label className="custom-checkbox-container">
            <input id={props.id} name={props.id} type="checkbox" checked={props.checked} onChange={props.onChange} required={props.required? true: false}/>
            <span className="custom-checkbox-checkmark"></span>
        </label>
    )
}