import React, { Fragment } from 'react';
import BusinessPurchaseForm from './BusinessPurchaseForm';
import CustomerServiceForm from './CustomerServiceForm';
import CovidTestKitForm from './CovidTestKitPurchaseForm';
import CovidTestKitFormHK from './CovidTestKitFormHK';
import TaxExempt from './TaxExempt';
import Header from './Header';
import Menu from './Menu';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import WHITE_LIST from './constants';
import { lang } from './constants/enterprisePurchase/hk';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.height = 0;
    this.sendPostMessage = this.sendPostMessage.bind(this);
    this.sendSchedulerLink = this.sendSchedulerLink.bind(this);
    this.container = React.createRef();
    this.target = '';
  }

  componentDidMount() {
    window.addEventListener('load', this.sendPostMessage);
    window.addEventListener('resize', this.sendPostMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.sendPostMessage);
    window.removeEventListener('resize', this.sendPostMessage);
  }

  sendPostMessage(e) {
    if (this.height !== this.container.scrollHeight) {
      this.height = this.container.scrollHeight;
      window.parent.postMessage(
        {
          frameHeight: this.height, //becareful not to send sentitive data to unauthorised parties
        },
        '*',
      );
    }
  }

  sendSchedulerLink(linkObj) {
    // linkObj: { type: 'sendSchedulerLink', value: the_link }
    window.parent.postMessage(linkObj, WHITE_LIST[this.target]);
  }

  render() {
    return (
      <Router>
        <div
          ref={(c) => {
            this.container = c;
          }}
        >
          <Switch>
            <Route path="/" exact>
              <BusinessPurchaseForm sendPostMessage={this.sendPostMessage} />
            </Route>
            <Route path="/covid-test-kit-hk">
              <CovidTestKitFormHK
                {...lang.en}
                sendPostMessage={this.sendPostMessage}
              />
            </Route>
            <Route path="/covid-test-kit-tw">
              <CovidTestKitFormHK
                {...lang.zhTw}
                sendPostMessage={this.sendPostMessage}
              />
            </Route>
            <Route path="/covid-test-kit">
              <CovidTestKitForm sendPostMessage={this.sendPostMessage} />
            </Route>
            <Fragment>
              <Header />
              <Menu />
              <Route path="/customer-service">
                <CustomerServiceForm sendPostMessage={this.sendPostMessage} />
              </Route>
              {/* <Route path="/teleclinic">
                <TeleclinicForm sendPostMessage={this.sendPostMessage} />
              </Route> */}
              {/* <Route path="/donation">
                <DonationForm sendPostMessage={this.sendPostMessage} />
              </Route> */}
              <Route path="/tax-exempt" sendPostMessage={this.sendPostMessage}>
                <TaxExempt />
              </Route>
            </Fragment>
          </Switch>
        </div>
      </Router>
    );
  }
}
