export const lang = {
    'en': {
        lang:'en',
        header: 'Enterprise Purchase Request Form',
        firstName:'First Name',
        lastName:'Last Name',
        email:'Contact Email',
        phone:'Phone',
        title: 'Contact Title',
        organization: 'Organization Name',
        organizationType: 'Organization Type',
        address1: 'Address 1',
        address2: 'Address 2',
        productName: 'Please select the product you would like to purchase',
        quantity: 'Estimated Order Quantity',
        date: 'Date you wish to receive the product',
        note: 'Note(Optional)',
        notePlaceHolder: 'Anything you want to let us know regards your order? Leave us a note here.',
        submit: 'Submit',
        subscribe: 'I would like to receive updates and products information emails via Email from iHealthlabs.',
        error: 'An Error Occurred!',
        errorMessage:'An error occurred. Please try again later.',
        thankyou:'Thank you! Your request has been submitted. We will contact you as soon as possible.',
        required: 'This field is required.',
        minimum: 'This is a required field and minimum 101.',
        orgTypeList: [
            'ACA Affiliated Camp',
            'College/University',
            'Employer',
            'Government Affiliated',
            'Hospital',
            'Long Term Care',
            'Physican Office',
            'Primary/Secondary Education',
            'Public Health',
            'Reference Lab',
            'Retail Health/Pharmacy',
            'Urgent Care',
            'Other'
        ]
    },
    'zhTw': {
        lang:'zh-tw',
        header: '企業批量採購申請表',
        firstName:'名',
        lastName:'姓',
        email:'聯繫電子郵件',
        phone:'電話',
        title: '聯繫人職務',
        organization: '機構名稱',
        organizationType: '組織類型',
        address1: '地址 1',
        address2: '地址 2',
        productName: '請選擇您要購買的產品',
        quantity: '預計訂購數量',
        date: '您希望收到產品的日期',
        note: '留言（可選）',
        notePlaceHolder: '關於您的訂單，您有什麼想告訴我們的嗎？在這裡給我們留言。',
        submit: '提交',
        subscribe: '我想通過電子郵件接收來自 iHealthlabs 的更新和產品信息電子郵件。',
        error: '發生錯誤！',
        errorMessage:'請稍後再試。',
        thankyou:'謝謝！您的申請已經提交。我們會盡快與您聯繫。',
        required: '這是必填字段。',
        minimum: '這是必填字段，最少 101。',
        orgTypeList: [
            '小學/中學教育',
            '學院/大學',
            '雇主',
            '政府附屬',
            '醫院',
            '長期護理',
            '醫師辦公室',
            '公共衛生',
            '實驗室',
            '零售/藥房',
            '緊急護理',
            '其他'
        ]
    }
}