import React from 'react';
import {
  Container, Col, Form,
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import checkImg from './imgs/check.svg';

export default class CovidTestKitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contact_title: '',
            organization_name: '',
            organization_type: '',
            state: '',
            city: '',
            zip: '',
            street: '',
            product_name: '',
            quantity: '101',
            receive_date: '',
            note: '',
            receive_updates: false,
            submitted: false,
            validate: {},
            error: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount(){
        this.props.sendPostMessage({type:'resize', target: window.document });   
    }

    resetForm() {
        this.setState({first_name: '',
        last_name: '',
        email: '',
        phone: '',
        contact_title: '',
        organization_name: '',
        organization_type: '',
        state: '',
        city: '',
        zip: '',
        street: '',
        product_name: '',
        quantity: '101',
        receive_date: '',
        note: '',
        receive_updates: false,
        submitted: false,
        validate: {},
        error: ''});
    }


    // check all fields in state are filled
    async isFormValid(field) {
        if(field) {
            return this.state.validate[field]===true;
        }
       const fields = ['first_name', 'last_name', 'email', 'phone', 'contact_title', 'organization_name', 'organization_type', 'state', 'city', 'zip', 'street', 'product_name','quantity', 'receive_date'].reduce((o, key) => Object.assign(o, {[key]: this.state[key]!==''}), {});
        if(this.state.quantity<101) {
            fields.quantity = false;
        }
        const valid = Object.keys(this.state.validate).length !== 0 && Object.values(fields).every(value => value === true);
        if(!valid) {
            await this.setState({validate: fields});
            return undefined;
        }
        else {
            return true;
        }
    }



    handleChange = async (event) => {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if(value==="true"){
          value = true;
        }else if (value==="false"){
          value = false;
        }
        const { name } = target;
        const valid = value !== '';
        const validate = Object.assign({}, this.state.validate, {[name]: valid});
        await this.setState({
          [name]: value,
            validate
        });
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const r = await this.isFormValid();
        if(r === undefined) {
            return;
        }
        const allTrue = Object.keys(this.state.validate).length !== 0 && Object.values(this.state.validate).every(value => value === true);
        if(allTrue){
            console.log('submitting form');
            const data = {...this.state};
            delete data.validate;
            delete data.error;
            delete data.submitted;
            fetch('/forms/covid-test-kit',
            {
              method: "POST",
              cache: "no-cache",
              mode: 'cors',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.state)
            }).then(response => {
              return response.json();
              // return response.json(); //then consume it again, the error happens
            }).then(r => {
              console.log(r);
              if(r.message.indexOf('error') >= 0) {
                  console.log(r.message);
                this.setState({error: r.message});
              }
              else if(r.message === 'success'){
                this.setState({submitted: true});
              }
              else {
                alert("Oops, please make sure the infomation you've entered is correct.")
              }
            })
            .catch((e)=>{
              console.log(e);
              this.setState({error: e});
            })
        }
        else{
            this.isFormValid();
        }
    }

    // render form section
    renderFormSection = () => {
        return ( <div className="form-content">
            <Form>
                <FormGroup row>
                    <Col md="6">
                        <Label for="first_name">
                        First Name
                        </Label>
                        <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                        invalid={this.state.validate.first_name===false}
                        />
                        <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="last_name">
                        Last Name
                        </Label>
                        <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.last_name===false}
                        />
                        <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="6">
                        <Label for="email">
                        Contact Email
                        </Label>
                        <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.email===false}
                        /> 
                        <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="phone">
                        Phone
                        </Label>
                        <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.phone===false}
                        />
                        <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="contact_title">
                    Contact Title
                    </Label>
                    <Input
                    id="contact_title"
                    name="contact_title"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.contact_title===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                
                <FormGroup row>
                    <Col md="12">
                    <Label for="organization_name">
                    Organization Name
                    </Label>
                    <Input
                    id="organization_name"
                    name="organization_name"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.organization_name===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="organization_type">
                    Organization Type
                    </Label>
                    <Input
                    id="organization_type"
                    name="organization_type"
                    type="select"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.organization_type===false}
                    >
                        <option></option>
                        <option>
                        ACA Affiliated Camp
                        </option>
                        <option>
                            College/University
                        </option>
                        <option>
                            Employer
                        </option>
                        <option>
                            Government Affiliated
                        </option>
                        <option>
                            Hospital
                        </option>
                        <option>
                            Long Term Care
                        </option>
                        <option>
                            Physican Office
                        </option>
                        <option>
                            Primary/Secondary Education
                        </option>
                        <option>
                            Public Health
                        </option>
                        <option>
                            Reference Lab
                        </option>
                        <option>
                            Retail Health/Pharmacy
                        </option>
                        <option>
                            Urgent Care
                        </option>
                        <option>
                            Other
                        </option>
                    </Input>
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="street">
                    Street Address
                    </Label>
                    <Input
                    id="street"
                    name="street"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.street===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="5">
                    <Label for="city">
                    City
                    </Label>
                    <Input
                    id="city"
                    name="city"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.city===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                    <Col md="2">
                    <Label for="state">
                    State
                    </Label>
                    <Input
                    id="state"
                    name="state"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.state===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                    <Col md="5">
                    <Label for="zip">
                    Zip
                    </Label>
                    <Input
                    id="zip"
                    name="zip"
                    type="text"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.zip===false}
                    />
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="product_name">
                    Please select the product you would like to purchase
                    </Label>
                    <Input
                    id="product_name"
                    name="product_name"
                    type="select"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.product_name===false}
                    >
                        <option></option>
                        <option>
                            2 Tests Per Pack
                        </option>
                        <option>
                            40 Tests Per Pack
                        </option>
                    </Input>
                    <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="6">
                    <Label for="quantity">
                    Estinmated Order Quantity
                    </Label>
                    <Input
                    id="quantity"
                    name="quantity"
                    type="number"
                    min='101'
                    value={this.state.quantity}
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                      invalid={this.state.validate.quantity===false}
                    />
                    <FormFeedback>
                        This is a required field and minimum 101.
                        </FormFeedback>
                    </Col>
                    <Col md="6">
                        <Label for="receive_date">
                            Date you wish to receive the product
                        </Label>
                        <Input
                        id="receive_date"
                        name="receive_date"
                        type="date"
                        onChange={(e) => {
                            this.handleChange(e)
                          }}
                          invalid={this.state.validate.receive_date===false}
                        />
                        <FormFeedback>
                        This is a required field.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12">
                    <Label for="note">
                    Note(Optional)
                    </Label>
                    <Input
                    id="note"
                    name="note"
                    type="textarea"
                    placeholder="Anything you want to let us know regards your order? Leave us a note here."
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="12" style={{paddingLeft:"35px"}}>
                    <Label for="receive_updates" className="justify-content-center" style={{fontWeight: 'normal'}}>
                    <Input
                    id="receive_updates"
                    name="receive_updates"
                    type="checkbox"
                    onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                    I would like to receive updates and products information emails via Email from iHealthlabs.
                    </Label>
                    </Col>
                </FormGroup>
                <button onClick={(e)=>{
                    this.handleSubmit(e)
                }} className="btn" style={{color: 'white', fontWeight: 'bold', fontSize:'24px', width: '100%'}}>
                    Submit
                </button>
                </Form>
        </div>);
    }

    render() {
        if(this.state.submitted) {
            return <div className="d-flex flex-column align-items-center mt-3 mb-3" style={{width: "100%"}}>
                    <img src={checkImg} alt="Success" style={{width: '87.5px', height: '87.5px', marginBottom: '41px'}}/>
                    <p style={{marginBottom: "64px"}}> Thank you! Your request has been submitted. We will contact you as soon as possible.</p>
                    <button className="btn" style={{color: '#fff', padding: "8px 42px"}} onClick={()=>this.resetForm()}>
                        Ok
                    </button>
                </div>;
        } else if ( this.state.error) {
            return(<div className="d-flex flex-column align-items-center mt-3 mb-3" style={{width: "100%"}}>
                <h1>An Error Occurred!</h1>
                <p>Please try again later or send us an email at salestax@ihealthlabs.com.</p>
                </div>)
        }
        return (
            <Container className="d-flex flex-column align-items-center mt-3 mb-3">
                <div id="CovidTestKitForm" className="" style={{maxWidth: '706px'}}>
                    <h1>Enterprise Purchase Request Form</h1>
                    <p>Please note that we are currently selling our product in the United States ONLY.  </p>
                    {this.renderFormSection()}
                </div>
            </Container>
        );
    }


}