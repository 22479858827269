import React from 'react';
import { NavLink } from 'react-router-dom';

const Menu = (props) => {
  return (
    <div className="center inquiry-form-menu">
      <ul className="contact-link-menu" style={{ width: '1068px' }}>
        {/* <li className="contact-link-item" id="teleclinic"><NavLink exact to="/teleclinic" activeClassName="active"><span>Teleclinic & Unified Care Program</span></NavLink></li> */}
        {/* <li className="contact-link-item" id="donation"><NavLink exact to="/donation" activeClassName="active"><span>Donation Request</span></NavLink></li> */}
        <li className="contact-link-item" id="customer">
          <NavLink exact to="/customer-service" activeClassName="active">
            <span>Customer Service</span>
          </NavLink>
        </li>
        <li className="contact-link-item" id="tax-exempt">
          <NavLink exact to="/tax-exempt" activeClassName="active">
            <span>Tax Exempt</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
