const WHITE_LIST = [
    'https://support.ihealth-eng.com/',
    'https://support.ihealth-eng.com',
    'https://forms.ihealth-eng.com/',
    'https://forms.ihealth-eng.com',
    'https://ihealthlabs.com',
    'https://ihealthlabs.com/',
    'https://ihealthlabs.com/contact-us/business-inquiry',
    'https://ihealthlabs.com/contact-us/business-inquiry/',
    // 'https://ihealthlabs.com/contact-us/donation-request',
    // 'https://ihealthlabs.com/contact-us/donation-request/',
    'https://ihealthlabs.com/contact-us/teleclinic-request',
    'https://ihealthlabs.com/contact-us/teleclinic-request/',
    'https://ihealthlabs.com/contact-us/customer-service',
    'https://ihealthlabs.com/contact-us/customer-service/',
    'https://ihealthlabs.com/pages/enterprise-purchase',
    'https://ihealthlabs.com/pages/enterprise-purchase/',
    'https://ihealthlabs.com/pages/contact-us',
    'https://ihealthlabs.com/pages/contact-us/',
    'https://ihealthlabs.com/pages/solutions',
    'https://ihealthlabs.com/pages/solutions/',
    'https://ihealthlabsstg.wpengine.com',
    'https://ihealthlabsstg.wpengine.com/',
    'https://ihealthlabsdev.wpengine.com',
    'https://ihealthlabsdev.wpengine.com/',
]

export default WHITE_LIST;