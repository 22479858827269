import React, { Fragment } from 'react';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Spinner,
} from 'reactstrap';

import ContactInfo from './ContactInfo';
import infoIcon from './imgs/info.png';

export default class CustomerServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'your-name': '',
      'your-email': '',
      'your-subject': '',
      'your-message': '',
      disabled: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    this.props.sendPostMessage({ type: 'resize', target: window.document });
  }

  resetForm = () => {
    this.setState({
      'your-name': '',
      'your-email': '',
      'your-subject': '',
      'your-message': '',
      disabled: false,
      loading: false,
    });
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({ disabled: true, loading: true });
    console.log(this.state);
    if (this.state.your_subject === '') {
      alert('Pleas select a category.');
      return;
    }

    const state = {
      ...this.state,
      'your-subject': `Contact us: ${this.state['your-subject']}`,
    };
    fetch('/forms/customer', {
      method: 'POST',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    })
      .then((response) => {
        return response.json();
      })
      .then((r) => {
        console.log(r.message);
        if (r.message.indexOf('error') >= 0) {
          alert('Please make sure the email address is valid.');
          this.setState({ disabled: false, loading: false });
          return;
        } else if (r.message === 'success') {
          alert('submit success!');
          this.resetForm();
        } else {
          alert(
            "Submit unsuccessful, please make sure the infomation you've entered is correct.",
          );
          this.setState({ disabled: false, loading: false });
        }
      })
      .catch((e) => {
        console.log(e);
        alert('Submit unsuccessful, please try again later.');
        this.setState({ disabled: false, loading: false });
      });
  }

  handleChange = async (event) => {
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  };

  renderName = () => {
    const yourName = this.state['your-name'];
    return (
      <FormGroup row>
        <Label sm={3}>Name *</Label>
        <Col sm={9}>
          <Input
            type="text"
            name="your-name"
            id="your-name"
            placeholder=""
            value={yourName}
            onChange={(e) => {
              this.handleChange(e);
            }}
            required
          />
        </Col>
      </FormGroup>
    );
  };
  renderEmail = () => {
    const email = this.state['your-email'];
    return (
      <FormGroup row>
        <Label sm={3}>Email *</Label>
        <Col sm={9}>
          <Input
            type="email"
            name="your-email"
            id="your-email"
            placeholder=""
            value={email}
            onChange={(e) => {
              this.handleChange(e);
            }}
            required
          />
        </Col>
      </FormGroup>
    );
  };
  renderCategory = () => {
    const subject = this.state['your-subject'];
    return (
      <FormGroup row>
        <Label sm={3}>Message Category*</Label>
        <Col sm={9}>
          <select
            id="your-subject"
            name="your-subject"
            value={subject}
            onChange={(e) => this.handleChange(e)}
            required
          >
            <option value="">PLEASE SELECT ONE</option>
            <option value="Technical Support Issue">
              Technical Support Issue
            </option>
            <option value="Pre-sales Inquiry">Pre-sales Inquiry</option>
            <option value="Status on a current order">
              Status on a current order
            </option>
            <option value="Change or Cancel a current unshipped order">
              Change or Cancel a current unshipped order
            </option>
            <option value="Other">Other</option>
          </select>
        </Col>
      </FormGroup>
    );
  };
  renderMessage = () => {
    const message = this.state['your-message'];
    return (
      <FormGroup row>
        <Label sm={3}>Message *</Label>
        <Col sm={9}>
          <Input
            type="textarea"
            name="your-message"
            id="your-message"
            placeholder=""
            value={message}
            onChange={(e) => {
              this.handleChange(e);
            }}
            style={{ height: '189px' }}
            required
          />
        </Col>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Spinner
          className="spinner"
          animation="border"
          role="status"
          style={{ display: this.state['loading'] ? 'block' : 'none' }}
          aria-label="loading"
        >
          Loading...
        </Spinner>
        <div className="form-content-fixed-width center">
          <p
            className="align-left service-message bold"
            style={{ fontSize: '20px', fontWeight: '24px' }}
          >
            <img
              className="icon icon-info"
              src={infoIcon}
              alt="info icon"
              aria-label="Information"
            />{' '}
            Expectation on our customer service during COVID-19 pandemic
          </p>
          <p className="align-left service-message">
            Dear customers, we value your satisfaction and voice. Tell us any
            problems you meet here, and we will solve your problem ASAP.{' '}
            <strong>
              However, due to COVID-19 pandemic and drastically increased
              demands on our products, our customer service response time will
              be longer than usual and our customer service call center is
              temporarily suspended. Thank you for your understanding.
            </strong>
          </p>
          <Container className="App">
            <p className="form-title">Please Leave us your message here.</p>
            <Form className="form" onSubmit={(e) => this.handleSubmit(e)}>
              <Row>
                <Col sm="12" className="required">
                  * required
                </Col>
              </Row>
              {this.renderName()}
              {this.renderEmail()}
              {this.renderCategory()}
              {this.renderMessage()}
              <Row>
                <Col xs="9" className="btn">
                  <Button disabled={this.state['disabled']}>
                    {this.state['loading'] ? (
                      <Spinner
                        className="spinner"
                        animation="border"
                        role="status"
                        style={{
                          display: this.state['loading'] ? 'block' : 'none',
                        }}
                        aria-label="loading"
                      >
                        Loading...
                      </Spinner>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
        <hr className="hr" />
        <div className="form-content-fixed-width center">
          <Container
            className="App"
            style={{ padding: 0, margin: 0, backgroundColor: '#fff' }}
          >
            <ContactInfo />
          </Container>
        </div>
      </Fragment>
    );
  }
}
