import React from 'react';
import { Container, Row, Col} from 'reactstrap';

const TaxExempt = () => {
    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <p>If you/your organization holds a valid sales tax exemption certificate, please send the certification with your registered email address to <a href="mailto:salestax@ihealthlabs.com">salestax@ihealthlabs.com</a>. Once valid the certification, we will set up a tax-exempt account associated with your email and notify you in email. You can then place orders in our online store with sales tax exempt.</p>
                    <p>*Once we receive you sales tax exempt request, we will process within 1 to 2 business days.</p>
                    <p>*For orders that already placed with sales tax collected, it usually takes 4 to 8 weeks to refund the sales tax to your original payment method.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default TaxExempt;