import React from 'react';
import {
  Container, Col, Row, Form,
  FormGroup, Label, Input,
  Button, Spinner
} from 'reactstrap';
import medicalMaskImg from './imgs/medical_mask.png';
import n95MaskImg from './imgs/n95.png'
import thermometerImg from './imgs/thermometer.png'
import soapImg from './imgs/hand_washer.png';
import surgicalMaskImg from './imgs/surgical-mask.png';
import Checkbox from './CustomCheckBox';


export default class BusinessPurchaseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          'organization_name': '',
          'shipping_address': '',
          'contact_person': '',
          'business_email': '',
          'phone_number': '',
          'disabled': false,
          'loading': false,
          'is_tax_exempt': false,
          'no_touch_thermometer': 0,
          'no_touch_thermometer_is_selected': false,
          'disposable_face_mask': 0,
          'disposable_face_mask_is_selected': false,
          'kn95_mask': 0,
          'kn95_mask_is_selected': false,
          'surgical_mask': 0,
          'surgical_mask_is_selected': false,
          'automatic_foaming_soap_dispenser': 0,
          'automatic_foaming_soap_dispenser_is_selected': false,
          'when_do_you_need': '',
          'how_do_you_know_about_us': '',
          'where_does_this_exemption_apply':'',
          'tax_id_type': '',
          'tax_id' : '',
          'business_type': '',
          'reason_for_exemption': '',
          'reason_for_exemption_detail': ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.selectedReason = React.createRef();
      }

      componentDidMount(){
        this.props.sendPostMessage({type:'resize', target: window.document });
      }

      resetForm = () => {
        this.setState({
          'organization_name': '',
          'shipping_address': '',
          'contact_person': '',
          'business_email': '',
          'phone_number': '',
          'disabled': false,
          'loading': false,
          'is_tax_exempt': false,
          'no_touch_thermometer': 0,
          'no_touch_thermometer_is_selected': false,
          'disposable_face_mask': 0,
          'disposable_face_mask_is_selected': false,
          'kn95_mask': 0,
          'kn95_mask_is_selected': false,
          'surgical_mask': 0,
          'surgical_mask_is_selected': false,
          'automatic_foaming_soap_dispenser': 0,
          'automatic_foaming_soap_dispenser_is_selected': false,
          'when_do_you_need': '',
          'how_do_you_know_about_us': '',
          'where_does_this_exemption_apply':'',
          'tax_id_type': '',
          'tax_id' : '',
          'business_type': '',
          'reason_for_exemption': '',
          'reason_for_exemption_detail': ''
        })
      }
      handleSubmit(e) {
        e.preventDefault();
        if(this.state.disposable_face_mask===0 && this.state.automatic_foaming_soap_dispenser===0 && this.state.kn95_mask===0 && this.state.no_touch_thermometer===0 &&this.state.surgical_mask===0) {
          alert("Please select a product.");
          return;
        }
        this.setState({'disabled': true, 'loading':true});
        console.log(this.state);
    
        fetch('/forms/business_new',
          {
            method: "POST",
            cache: "no-cache",
            mode: 'cors',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
          }).then(response => {
            return response.json();
            // return response.json(); //then consume it again, the error happens
          }).then(r => {
            console.log(r);
            if(r.message.indexOf('error') >= 0) {
              alert("Oops, please make sure the infomation you've entered is correct.");
              return;
            }
            else if(r.message === 'success'){
              alert('submit success!');
              this.resetForm();
            }
            else {
              alert("Oops, please make sure the infomation you've entered is correct.")
            }
          })
          .catch((e)=>{
            console.log(e);
            alert("Oops, an error occurred, please try again later.");
          }).finally(()=>{
            this.setState({'disabled': false, 'loading':false});
          })
      }
    
      handleChange = async (event) => {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if(value==="true"){
          value = true;
        }else if (value==="false"){
          value = false;
        }
        const { name } = target;
        await this.setState({
          [name]: value,
        });
      }

      handleCheckboxChange = async (event, id, amount) => {
        const { target } = event;
        const { name } = target;
        let quantity;
        console.log(name, target.checked)
        if(target.checked){
          quantity = amount;
        }else{
          quantity = 0;
        }
        await this.setState({
          [name]: target.checked,
          [id]: quantity
        });
      }

      handleQuantityChange = (e, type, id) => {
        let target;
        if(type!==null) {
          target = e.target.dataset;
        }else {
          target = e.target;
        }
        let value = parseInt(target.value);
        if(type==='minus'){
          if(value-1 >= target.min){
            value -= 1;
          }
        }else if(type==='plus'){
          if(value+1 <= target.max){
            value +=1 ;
          }
        }else if(value>target.max){
          value = target.max
        }else if(value < target.min){
          value = target.min
        }
        console.log(id ,value)
        this.setState({[id]:value})
      }

      renderHeader = () => {
        return <div className="center mb-4 p-4">
        <div className="center column">
          <p className="business-title">Need Healthcare Products for your business?</p>
          <p className="business-description">Our supplies are abundant to cater enterprise bulk purchase of large quantity demanded.<br/>
          We accept the orders of up to 50,000 units for enterprise purchases.</p>
        </div>
        </div>
      }

      renderOrganization = () => {
        const { organization_name } = this.state;
        return (
          <section>
            <label className="step-label">Step 4</label>
            <label className="step-name">Contact information</label>
            <p className="bold">Please leave your contact information.</p>
            <FormGroup row>
              <Label sm={2}>Organization Name *</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  name="organization_name"
                  id="organization_name"
                  placeholder=""
                  value={organization_name}
                  onChange={(e) => {
                    this.handleChange(e)
                  }}
                  required
                />
              </Col>
            </FormGroup>
            {this.renderShippingAddress()}
            {this.renderContact()}
            {this.renderEmail()}
            {this.renderPhone()}
          </section>
        )
      }

      renderShippingAddress = () => {
        const { shipping_address } = this.state;
        return (
          <FormGroup row>
              <Label sm={2}>Shipping Address *</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  name="shipping_address"
                  id="shipping_address"
                  placeholder=""
                  value={shipping_address}
                  onChange={(e) => {
                    this.handleChange(e)
                  }}
                  required
                />
              </Col>
            </FormGroup>
        )
      }
    
    
      renderContact = () => {
        const { contact_person } = this.state;
        return (
          <FormGroup row>
            <Label sm={2}>Contact Person *</Label>
            <Col sm={7}>
              <Input
                type="text"
                name="contact_person"
                id="contact_person"
                value={contact_person}
                onChange={(e) => this.handleChange(e)}
                required
              />
            </Col>
          </FormGroup>
        )
      }

      renderEmail = () => {
        const { business_email } = this.state;
        return (
          <FormGroup row>
            <Label sm={2}>Business Email *</Label>
            <Col sm={7}>
              <Input
                type="email"
                name="business_email"
                id="business_email"
                value={business_email}
                onChange={(e) => this.handleChange(e)}
                required
              />
            </Col>
          </FormGroup>
        )
      }
    
      renderPhone = () => {
        const { phone_number } = this.state;
    
        return (
          <FormGroup row>
            <Label sm={2}>Phone Number*</Label>
            <Col sm={7}>
              <Input
                type="text"
                name="phone_number"
                id="phone_number"
                value={phone_number}
                onChange={(e) => this.handleChange(e)}
                required
              />
            </Col>
          </FormGroup>
        )
      }

      renderTaxExempt = () => {
        const { is_tax_exempt } = this.state;
        return (
          <section id="tax-exempt-section">
            <label className="step-label">Step 1</label>
            <label className="step-name">Organization Type</label>
            <p>Is your organization a tax-exempt nonprofit 501(c) organization. *</p>
            <FormGroup check>
              <Label >
                <Input type="radio" name="is_tax_exempt" checked={is_tax_exempt} value={true} onChange={(e) => this.handleChange(e)} />{' '}
                Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label >
                <Input type="radio" name="is_tax_exempt" checked={!is_tax_exempt} value={false} onChange={(e) => this.handleChange(e)} />{' '}
                No
              </Label>
            </FormGroup>
            {this.state.is_tax_exempt? this.renderExemptForm() : ''}
          </section>
        )
      }

      renderExemptForm = () => {
        const { where_does_this_exemption_apply, tax_id_type, tax_id, business_type, reason_for_exemption, reason_for_exemption_detail} = this.state;

        const states = [
          'Alabama', 'Alasja', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 
          'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 
          'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
          'mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hamsphire', 'New Jersey', 'New Mexico', 
          'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 
          'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
          'West Virginia', 'Wisconsin', 'Wyoming', 'Armed Forces(AA)', 'Armed Forces(AE)', 'Armed Forces(AP)'
        ].map((row, key)=>{
          return <option value={row} key={key}>{row}</option>
        })

        const businessTypeList = [
          'Accommodation and Food Services',
          'Agricultural/Forestry/Fishing/Hunting',
          'Construction',
          'Finance or Insurance',
          'Information Publishing and Communications',
          'Manufacturing',
          'Mining',
          'Real Estate',
          'Rental and Leasing',
          'Retail Trade',
          'Transportation and Warehousing',
          'Utilities',
          'Wholesale Trade',
          'Business Services',
          'Professional Services',
          'Education and Health Care Services',
          'Nonprofit Organization',
          'Government',
          'Not a Business',
          'Other'
        ].map((row, key)=>{
          return <option value={row} key={key}>{row}</option>
        })

        const reasonForExemptionList = {
          'Federal Government Department': 'Dept. Name',
          'State or Local Government': 'Govt. Name',
          'Tribal Government': 'Tribe Name',
          'Foreign Diplomat': 'Deplomat ID',
          'Charitable Organization': 'Organization ID',
          'Religious or Educational Organization': 'Organization ID',
          'Resale': 'Resale ID',
          'Agricultural Production': 'Agricultural Prod. ID',
          'Industrial Production or Manufacturing': 'Production ID',
          'Direct Pay Permit': 'Permit ID',
          'Direct Mail': 'Direct Mail ID',
          'Other': 'Please explain'
        }
        const reasonForExemptionOptions = Object.keys(reasonForExemptionList).map((row, key)=>{
          return <option value={row} key={key}>{row}</option>
        })

        const title = reasonForExemptionList[this.state.reason_for_exemption];
        return (
          <div className="exemption-form">
            <p className="bold">Please provide certificate information for tax-exempt qualification.</p>
            <p>Note: It is your responsibility to be responsible for your tax-exempt qualification. If there is tax-exempt fraud detected, you will be held liable for any tax, interest, and civil and criminal penalties imposed by the state.</p>
            <FormGroup row>
              <Label sm={4}>Where does this exemption applied? *</Label>
              <Col sm={5}>
              <select id="where_does_this_exemption_apply" name="where_does_this_exemption_apply" value={where_does_this_exemption_apply} onChange={(e)=>this.handleChange(e)} required>
                  <option value=""></option>
                  {states}
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4}>Tax ID Type *</Label>
              <Col sm={5}>
                <select id="tax_id_type" name="tax_id_type" value={tax_id_type} onChange={(e)=>this.handleChange(e)} required>
                  <option value=""></option>
                  <option value="Federal Employer ID">Federal Employer ID</option>
                  <option value="State issued Exemption ID or Drivers License">State issued Exemption ID or Drivers License</option>
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4}>Tax ID *</Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="tax_id"
                  id="tax_id"
                  placeholder="123-4567-89"
                  value={tax_id}
                  onChange={(e) => this.handleChange(e)}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4}>Business Type *</Label>
              <Col sm={5}>
                <select id="business_type" name="business_type" value={business_type} onChange={(e)=>this.handleChange(e)} required>
                  <option value=""></option>
                  {businessTypeList}
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4}>Reason for Exemption *</Label>
              <Col sm={5}>
              <select ref={(e)=>this.selectedReason=e} id="reason_for_exemption" name="reason_for_exemption" value={reason_for_exemption} onChange={(e)=>this.handleChange(e)} required>
                  <option value=""></option>
                  {reasonForExemptionOptions}
                </select>
              </Col>
            </FormGroup>
            {this.state.reason_for_exemption !==''? <FormGroup row>
              <Label sm={4}>{title}</Label>
              <Col sm={5}>
                <Input 
                  type="text"
                  name="reason_for_exemption_detail"
                  id="reason_for_exemption_detail"
                  value={reason_for_exemption_detail}
                  onChange={(e)=> this.handleChange(e)}
                  />
              </Col>
            </FormGroup> 
            :''}
            
          </div>
        )
      }

      renderProducts = () => {
        const products = [
          {
            name: 'iHealth No Touch Thermometer FDA cleared. Instant reading.',
            img: thermometerImg,
            id: 'no_touch_thermometer_is_selected',
            minimum: 120,
            maximum: 50000,
            unit:'',
            quantity_id: 'no_touch_thermometer'
          },
          {
            name: 'Surgical Mask(50 per box). Reduces the chance of spreading airborne diseases.',
            img: surgicalMaskImg,
            id: 'surgical_mask_is_selected',
            minimum: 100,
            maximum: 50000,
            unit: 'Box',
            quantity_id: 'surgical_mask'
          },
          {
            name: 'Automatic Foaming Soap Dispenser-include 1 soap dispenser & 1 hand soap.',
            img: soapImg,
            id: 'automatic_foaming_soap_dispenser_is_selected',
            minimum: 20,
            maximum: 50000,
            unit: '',
            quantity_id: 'automatic_foaming_soap_dispenser'
          }
        ].map((p, k) => {
          const selected = this.state[p.id]? 'bordered': '';
          return (
            <Col xs='6' sm='4' md='4' key={k} className={`center mt-4 ${p.status==='Out of stock'? 'disabled': ''}`}>
              
              <Row className="h-75 w-90">
                <span className="position-absolute w-75 text-center">{p.status? p.status: ''}</span>
                <div className={`h-100 w-100 center px-3 py-3 bg-white ${selected}`}>
                  <label htmlFor={p.id}><img className="mw-100 mh-50" src={p.img} alt={p.name}></img></label>
                  <p>{p.name}</p>
                </div>
              </Row>
              <Row className="center">
                <div className="checkbox">
                  <Checkbox id={p.id} onChange={(e) => {this.handleCheckboxChange(e, p.quantity_id, p.minimum)}} checked={this.state[p.quantity_id]}/>
                </div>
              </Row>
              <Row className={`center w-100 flex-row flex-nowrap ${!this.state[p.id]? 'disabled':''} `}>
                <div className="w-75 d-flex justify-content-end">
                    <div className="minus d-inline-block text-center" data-min={p.minimum} data-max={10000} data-value={this.state[p.quantity_id]===0? p.minimum: this.state[p.quantity_id]} onClick={(e)=>this.handleQuantityChange(e, 'minus', p.quantity_id)}>-</div>
                    <input type="number" className="d-inline-block text-center border-left-0 border-right-0 quantity" id={p.quantity_id} name={p.quantity_id} min={p.minimum} max={p.maximum} step={1} value={this.state[p.quantity_id]===0? p.minimum: this.state[p.quantity_id]} onChange={(e)=>this.handleQuantityChange(e, null, p.quantity_id)} disabled={!this.state[p.id]}/>
                    <div className="plus d-inline-block text-center" data-min={p.minimum} data-max={10000} data-value={this.state[p.quantity_id]===0? p.minimum: this.state[p.quantity_id]} onClick={(e)=>this.handleQuantityChange(e, 'plus', p.quantity_id)}>+</div>
                </div>
                <span className=" d-inline align-middle p-1 ml-1 w-25">{p.unit}</span>
              </Row>
              <Row className={`${!this.state[p.id]? 'disabled':''}`}><span>(Minimal Quantity: {p.minimum})</span></Row>
            </Col>
          )
        });

        return (
          <section>
            <label className="step-label">Step 2</label>
            <label className="step-name">Select Products</label>
            <p>Please select the products and enter the quantity. *</p>
              <div className="center business-header">
                <Row>
                  {products}
                </Row>
              </div>
          </section>
        )
      }

      renderExpectation = () => {
        const { when_do_you_need } = this.state;
        return (
          <section>
            <label className="step-label">Step 3</label>
            <label className="step-name">Expectation</label>
            <p>When do you need the products? *</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup>
                      <select id="when_do_you_need" name="when_do_you_need" value={when_do_you_need} onChange={(e)=>this.handleChange(e)} required>
                        <option value=""></option>
                        <option value="ASAP">ASAP</option>
                        <option value="1 Week+">1 Week+</option>
                        <option value="2 Week+">2 Week+</option>
                        <option value="3 Week+">3 Week+</option>
                        <option value="4 Week+">4 Week+</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
          </section>
        )
      }

      renderHowYouKnowAboutUs = () => {
        const { how_do_you_know_about_us } = this.state;
        return (
          <section>
            <label className="step-label">Step 5</label>
            <label className="step-name">Additional information</label>
            <p>How do you know about us? *</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup>
                      <select id="how_do_you_know_about_us" name="how_do_you_know_about_us" value={how_do_you_know_about_us} onChange={(e)=>this.handleChange(e)} required>
                        <option value=""></option>
                        <option value="Organization Searching">Organization Searching</option>
                        <option value="Google Ads">Google Ads</option>
                        <option value="Email Pushing">Email Pushing</option>
                        <option value="I am a iHealth product user">I am a iHealth product user</option>
                        <option value="Recommended by my friends">Recommended by my friends</option>
                        <option value="Youtube">Youtube</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Others">Others</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
          </section>
        )
      }

      renderAgreement = () => {
        return (
          <Row>
            <Col xs={12} sm={8}>
              <p>By submitting the form, our customer service will contact you ASAP to confirm your order. If you need to contact our sales, please send email to <a style={{color:'#111111', textDecoration:'underline'}} href="mailto:sales@ihealthlabs.com">sales@ihealthlabs.com</a> </p>
            </Col>
          </Row>
        )
      }
    
      render() {
    
        return (
          <div id="enterprise-purchase-form">
            {this.renderHeader()}
            <div className="form-content center">
              <Container className="App" >
                <h3 className="text-center font-weight-bold">Enterprise Purchase Inquiry Form</h3>
              <Form className="form" onSubmit={(e) => this.handleSubmit(e)} >
                <Row>
                  <Col sm="12" className="required">* required</Col>
                </Row>
                {this.renderTaxExempt()}
                <hr />
                {this.renderProducts()}
                <hr />
                {this.renderExpectation()}
                <hr />
                {this.renderOrganization()}
                <hr />
                {this.renderHowYouKnowAboutUs()}
                <hr />
                {this.renderAgreement()}
                <div  className="btn">
                  <Button disabled={this.state['disabled']}>{this.state['loading']? <Spinner className="spinner" animation="border" role="status" style={{display: this.state['loading']? 'block': 'none'}} aria-label="loading">Loading...</Spinner> :'Submit'}</Button>
                </div>
              </Form>
            </Container >
          </div>
        </div>
        );
      }
}