import React from 'react';
import contactImg from './imgs/contact-us.png';

const Header = () => {
    return (
        <div className="contact-us-header" id="contact-us-header">
            <div className="header-space-around">
                <div className="contact-message">
                    <span>Contact us</span>
                    <span>We are here listening ...</span>
                </div>
                <img className="contact-image" src={contactImg} alt="contact us"/>
            </div>
        </div>
    )
}

export default Header;